import { Component } from 'react'
import Icon from 'components/icon'
import styles from './toggle_filter.scss'
import SpecialitiesModal from './modal'

export default class ToggleContent extends Component {
  static defaultProps = {
    extended: true,
  }

  state = {
    extended: this.props.extended,
    isOpen: false,
  }

  openModal = e => {
    e.stopPropagation()
    e.preventDefault()

    this.setState({ isOpen: true })
  }

  render() {
    const { extended, isOpen } = this.state
    const { label, helpMe, children } = this.props

    return (
      <div className="toggle-wrapper">
        <div className="toggle-filter" onClick={() => this.setState({ extended: !extended })}>
          <span className="overline">
            {label}
            {helpMe && (
              <Icon
                name="v2-info"
                className={'pull-right'}
                fontSize="16px"
                onClick={this.openModal}
              />
            )}
          </span>
          <Icon
            name="caret-up"
            className={`pull-right hidden-xs ${extended ? 'caret-flipped' : ''}`}
            fontSize="8px"
          />
        </div>

        <SpecialitiesModal isOpen={isOpen} closeModal={() => this.setState({ isOpen: false })} />

        <div className={`toggle-children ${extended ? 'shown' : ''}`}>{children}</div>
        <style jsx global>
          {styles}
        </style>
      </div>
    )
  }
}
