import React, { useState, useContext } from 'react'
import Modal from 'components/modal'
import ReactPaginate from 'react-paginate'
import styles from './modal.scss'
import { GlobalContext } from 'components/global'

const SpecialitiesModal = ({ isOpen, closeModal }) => {
  const [page, setPage] = useState(0)
  const { config } = useContext(GlobalContext)
  const specialties = Object.values(config?.provider?.SPECIALTIES || {})

  const onPageChange = ({ selected }) => {
    setPage(selected * 3)
  }

  return (
    <>
      {specialties.length > 0 && (
        <Modal isOpen={isOpen} onRequestClose={closeModal}>
          <h3 className="header-sm mb-3">Glossary Of Provided Specialties</h3>

          {specialties.slice(page, page + 3).map(item => (
            <div className="mb-3" key={item.slug}>
              <h4 className="overline">{item.display_name}</h4>
              <p className="body-text">{item.blurb}</p>
            </div>
          ))}

          <ReactPaginate
            pageCount={Math.floor(specialties.length / 3)} // Change to Math.ceil when we add new elements
            marginPagesDisplayed={1}
            pageRangeDisplayed={5}
            onPageChange={onPageChange}
            containerClassName="pagination"
            activeClassName="active"
            pageClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            previousLabel={<i className="fa fa-left-caret" />}
            nextLabel={<i className="fa fa-right-caret" />}
          />
        </Modal>
      )}

      <style jsx>{styles}</style>
    </>
  )
}

export default SpecialitiesModal
